import React from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import DownloadBlock from "../components/downloadBlock/downloadBlock"
import pageStyles from "../components/pageStyles/resources.module.scss"
import ContactBlock from "../components/contactBlock/contactBlock"

// Query sanity to get files
export const data = graphql`
  {
    allSanityResource {
      edges {
        node {
          _createdAt(formatString: "MM-Y")
          file {
            asset {
              url
              originalFilename
            }
          }
          title
          description
          link
        }
      }
    }
  }
`

const Resources = ({ data }) => {
  // Extract file information from query
  const edges = data.allSanityResource.edges
  const files = edges.map(file => {
    return file.node
  })

  console.log("files :", files)

  return (
    <Layout scroll={true}>
      <div className={pageStyles.resources}>
        <div className={pageStyles.intro}>
          <h1 style={{ color: "#E1873D" }}>Marketing Resources</h1>
          <p>
            Here you can find a range of downloads for brochures, catalogues and
            other bits that you might find useful.
          </p>
        </div>

        <div className={pageStyles.files}>
          {files.map(file => (
            <DownloadBlock
              title={file.title}
              description={file.description}
              date={file._createdAt}
              file={file.file}
              link={file.link}
            />
          ))}
          <ContactBlock />
        </div>
      </div>
    </Layout>
  )
}

export default Resources
