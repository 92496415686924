import React from "react"
import styles from "./contactBlock.module.scss"

const ContactBlock = () => {
  return (
    <div className={styles.contactBlock}>
      <p>
        Can’t find what you’re looking for? Get in touch with our distributor
        Global Air Supplies.
      </p>
      <a href="/contact">CONTACT GAS</a>
    </div>
  )
}

export default ContactBlock
