import React from "react"
import pageStyles from "./downloadBlock.module.scss"

const DownloadBlock = ({ date, title, description, file, link }) => {
  return (
    <div className={pageStyles.container}>
      <h4>{date}</h4>
      <h2>{title}</h2>
      <p>{description}</p>
      {link && (
        <>
          <a
            className={pageStyles.viewLink}
            href={link}
            target="_blank"
            rel="noopener norefferer"
          >
            View online
          </a>
          {/* <span className={pageStyles.or}>or</span> */}
        </>
      )}
      <a
        className={pageStyles.button}
        href={`${file.asset.url}?=${file.asset.originalFilename}`}
      >
        DOWNLOAD
      </a>
    </div>
  )
}

export default DownloadBlock
